// Entry point for the build script in your package.json
import "@hotwired/turbo";
import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
import "chartkick/chart.js";
import 'tw-elements';
window.Alpine = Alpine
window.Alpine.start()

import "./controllers";
import "./turbo_progress_bar";

