
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
    static targets = ['options']

    connect() {
        this.dropDownId = uuidv4();
        this.closeListener = window.addEventListener("close-dropdown", (e) => this.close());
        this.openListener = window.addEventListener("open-dropdown", (e) => {
            if (this.dropDownId != e.detail.id) {
                this.close()
            }
        });
    }

    disconnect() {
        window.removeEventListener("close-dropdown", this.closeListener);
        window.removeEventListener("open-dropdown", this.openListener);
    }

    toggle() {
        if (this.optionsTarget.classList.contains("hidden")) {
            const openDropDownEvent = new CustomEvent("open-dropdown", { detail: { id: this.dropDownId } })
            window.dispatchEvent(openDropDownEvent)
            enter(this.optionsTarget)
        } else {
            leave(this.optionsTarget)
        }

    }

    close() {
        if (!this.optionsTarget.classList.contains("hidden")) {
            leave(this.optionsTarget)
        }
    }
}