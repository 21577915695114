import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
// Connects to data-controller="autocomplete"
export default class extends Controller {
  static values = { url: String, label: String }
  static targets = ['input', 'hidden', 'results', 'wrapper', 'button']
  static debounces = ['index']

  connect() {
    useDebounce(this)
    this.hideAndRemoveOptions()
    this.isLoading()
  }

  isLoading(val = false) {
    const iconSpinner = `
      <svg class="animate-spin mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    `
    const iconSearch = `
      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
    `

    val ? 
      this.buttonTarget.innerHTML = iconSpinner : 
      this.buttonTarget.innerHTML = iconSearch
  }

  index(event) {
    if (event.key === 'Enter') return

    const originUrl = window.location.origin
    const url = `${originUrl}/${this.urlValue}?query=${this.inputTarget.value}`

    this.isLoading(true)

    fetch(url)
      .then(response => response.json())
      .then(data => this.fill(data))
      .finally(
        window.setTimeout(() => this.isLoading(false), 1500)
      )
  }

  fill(data) {
    if (!data.length) return

    this.wrapperTarget.classList.remove('hidden')

    let html = ''

    data.forEach(item => {
      html += `
        <li 
          class="cursor-pointer relative select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-100" 
          id="option-${item.id}" 
          tabindex="-1" 
          role="option"
          data-autocomplete-value="${item.id}"
          data-action="click->autocomplete#onSelect"
        >
          ${item[this.labelValue]}
        </li>`
    })
    
    this.resultsTarget.innerHTML = html
  }

  onSelect(event) {
    if (!(event.target instanceof Element)) return
    const selected = event.target
    if (selected) this.handleSelect(selected)
  }

  handleSelect(selected) {
    this.inputTarget.value = selected.textContent.trim()
    this.hiddenTarget.value = selected.getAttribute("data-autocomplete-value")

    this.inputTarget.focus()
    this.hideAndRemoveOptions()
  }

  hideAndRemoveOptions() {
    this.resultsTarget.innerHTML = null
    this.wrapperTarget.classList.add('hidden')
  }
}
