import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js';



export default class extends Controller {
    static targets = ['pessoaSelect', 'clienteId', 'grupoTrabalhoId', 'posicaoId', 'pessoasContainer', 'pessoasList']

    select(event) {
        let clienteId = this.clienteIdTarget.value;
        let grupoTrabalhoId = this.grupoTrabalhoIdTarget.value;
        let posicaoId = this.posicaoIdTarget.value;

        let payload = {
            alocacao: {
                posicao_id: posicaoId,
                usuario_id: event.params.payload.id
            }
        }

        post(`/clientes/${clienteId}/grupo_trabalhos/${grupoTrabalhoId}/posicoes/${posicaoId}/alocacoes`, {
            body: payload,
            responseKind: 'turbo-stream'
        });

        const closeModalEvent = new CustomEvent("modal-close", {
            detail: {}
        });
        document.getElementById("modal-alocacao").dispatchEvent(closeModalEvent);

    }


    search() {
        let query = this.pessoaSelectTarget.value;
        console.log(`Buscar por: ${query} - `)
        get(`/pessoas?query=${query}&partial=alocacoes`, {
            responseKind: 'turbo-stream'
        });

    }
}