import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js';

export default class extends Controller {
    static targets = ["meta", "metas", "fatores", "eixo", "feedback", "tipoFeedback"];


    connect() {
        this.form = {
            metas: [],
            salario: null,
            bonus: null,
            feedback: null,
        };
    }

    setMaturidade(event) {
        let { altura, eixo } = event.params;
        this.form[eixo] = altura;
        event.srcElement.dispatchEvent(new CustomEvent('eixo', { detail: { eixo: eixo, altura: altura }, bubbles: true }))
    }

    addMeta() {
        this.form.metas.push(
            {
                descricao: this.metaTarget.value,
                fatores: this.fatoresTarget.value,
                eixo: this.eixoTarget.selectedOptions[0].value
            });
        this.cleanForm();
        this.metasTarget.innerHTML = this.form.metas.map((meta, i) => this.metaTemplate(meta, i)).join("");
    }

    removeMeta(index) {
        this.form.metas.splice(index, 1);
        this.metasTarget.innerHTML = this.form.metas.map((meta, i) => this.metaTemplate(meta, i)).join("");
    }

    cleanForm() {
        this.metaTarget.value = "";
        this.fatoresTarget.value = "";
        this.eixoTarget.selectedIndex = 0;
    }

    metaTemplate(meta, index) {
        return `<tr class="bg-white">
        <td class="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">${meta.descricao}</td>
        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <a  class="cursor-pointer text-red-600 hover:text-excluir-900" data-form_ciclo-index-param="${index}" data-action="click->form_ciclo#removeMeta">Excluir</a>
        </td>
      </tr>`
    }

    finish() {
        this.form.feedback = this.tipoFeedbackTarget.selectedOptions[0].value;

        post("/ciclos", {
            body: this.form,
            responseKind: 'turbo-stream'
        });
    }

}
