import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
    static targets = ["container", "backdrop", "panel", "closeButton", "form"]
    static values = {
        hasError: Boolean
    }

    connect() {

        console.log("HasError", this.hasErrorValue)
        document.addEventListener('turbo:submit-end', this.handleSubmit)
        this.containerTarget.classList.remove("hidden")
        if (!this.hasErrorValue) {
            enter(this.backdropTarget)
            enter(this.closeButtonTarget)
            enter(this.panelTarget)
        }
        const closeDropDownEvent = new CustomEvent("close-dropdown", { detail: {} })
        window.dispatchEvent(closeDropDownEvent)

    }

    disconnect() {
        document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }

    close() {
        Promise.all([
            leave(this.backdropTarget),
            leave(this.closeButtonTarget),
            leave(this.panelTarget)
        ]).then(() => {
            this.element.remove()
            //this.containerTarget.classList.add("hidden")
        })
        // Remove the modal element so it doesn't blanket the screen


        // Remove src reference from parent frame element
        // Without this, turbo won't re-open the modal on subsequent clicks
        this.element.closest("turbo-frame").src = undefined
    }

    handleKeyup(e) {
        if (e.code == "Escape") {
            this.close()
        }
    }

    handleSubmit = (e) => {
        if (e.detail.success) {
            this.close()
        }
    }

    submit() {
        this.formTarget.requestSubmit();
    }
}