import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import { useClickOutside, useDebounce } from "stimulus-use";

const RADIO_SELECTION_TOGGLE = "radio-selection-toggle";

export default class extends Controller {
  static values = { partial: String };
  static targets = [
    "pessoaSelect",
    "radioButton",
    "usuarioId",
    "pessoasContainer",
    "pessoasList",
    "notFound",
    "button",
  ];
  static debounces = ["search"];

  connect() {
    useClickOutside(this);
    useDebounce(this);

    this.close();
    this.tipo = null;
    this.currentIcon = this.buttonTarget.innerHTML;

    document.addEventListener(
      RADIO_SELECTION_TOGGLE,
      this.handleSelection.bind(this)
    );
  }

  toggle(event) {
    event.stopImmediatePropagation();

    const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value,
      },
    });

    document.dispatchEvent(selectionEvent);
    return false;
  }

  select(event) {
    const { payload } = event.params;
    this.usuarioIdTarget.value = payload.id;
    this.pessoaSelectTarget.value = payload.nome;
    this.pessoasListTarget.innerHTML = "";
    this.pessoaSelectTarget.focus();
  }

  handleSelection(event) {
    const { value } = event.detail;
    this.tipo = value;
    this.pessoasContainerTarget.classList.remove("hidden");

    this.close();
  }

  openByButton() {
    if (!this.opened) {
      this.opened = true;
      this.search();
    } else {
      this.close();
    }
  }

  withProgress(request) {
    this.isLoading(true);

    return request.then((response) => {
      window.setTimeout(() => this.isLoading(false), 1000);
      return response;
    });
  }

  search() {
    const query = this.pessoaSelectTarget.value;
    this.usuarioIdTarget.value = "";
    const url = `/pessoas?tipo=${this.tipo}&query=${query}&partial=${this.partialValue}`;

    const request = new FetchRequest("get", url, {
      responseKind: "turbo-stream",
    });

    return this.withProgress(request.perform());
  }

  close() {
    this.opened = false;

    if (this.hasPessoasListTarget) {
      this.pessoasListTarget.innerHTML = "";
    }

    if (this.hasNotFoundTarget) {
      this.notFoundTarget.innerHTML = "";
    }
  }

  isLoading(val = false) {
    const iconSpinner = `
      <svg class="animate-spin mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    `;

    val
      ? (this.buttonTarget.innerHTML = iconSpinner)
      : (this.buttonTarget.innerHTML = this.currentIcon);
  }
}
