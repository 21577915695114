
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
    static targets = ['group']

    change(e) {
        let { event, type } = e.params;
        let value = e.srcElement.value;
        this.groupTargets.forEach(group => {
            if (group.dataset.groupEvent == event) {
                if (group.dataset.groupShowWhen.split(";").includes(value)) {
                    enter(group, "conditional")
                } else {
                    leave(group, "conditional")
                }
            }
        });

    }


}