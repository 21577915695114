import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';
const spinner = '<div class="lds-dual-ring"></div>'

export default class extends Controller {
    static targets = ["custo_hora", "impostos", "valor_spread", "hora_venda", "valor_mensal", "custo_mensal"];


    connect() {

        this.custo_horaTarget.innerHTML = "-"
        this.impostosTarget.innerHTML = "-"
        this.valor_spreadTarget.innerHTML = "-"
        this.hora_vendaTarget.innerHTML = "-"
        this.valor_mensalTarget.innerHTML = "-"
        this.custo_mensalTarget.innerHTML = "-"
    }

    async calcular(event) {
        this.setFields(spinner);
        let { salario, percentual } = event.params
        var url = `https://raro-calculadora.herokuapp.com/api/calculos?salario=${salario}&spread=${percentual}&clt=true`;
        const response = await get(url, { headers: { "Accept": "*/*" } });
        if (response.ok) {
            const body = await response.text
            let json = JSON.parse(body)

            this.custo_horaTarget.innerHTML = this.formatCurrency(json.custo_hora)
            this.impostosTarget.innerHTML = this.formatCurrency(json.impostos)
            this.valor_spreadTarget.innerHTML = this.formatCurrency(json.spread)
            this.hora_vendaTarget.innerHTML = this.formatCurrency(json.valor_hora_venda)
            this.valor_mensalTarget.innerHTML = this.formatCurrency(json.valor_venda)
            this.custo_mensalTarget.innerHTML = this.formatCurrency(json.custo_mensal)

        } else {
            this.setFields("erro");
        }


    }

    formatCurrency(value) {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }


    setFields(value) {
        this.custo_horaTarget.innerHTML = value
        this.impostosTarget.innerHTML = value
        this.valor_spreadTarget.innerHTML = value
        this.hora_vendaTarget.innerHTML = value
        this.valor_mensalTarget.innerHTML = value
        this.custo_mensalTarget.innerHTML = value

    }


}
