import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js';



export default class extends Controller {
    static targets = ['posicao', 'grupo', "clientes"]

    connect() {
        console.log("Group Connected", new Date());
        this.boundHandlerOnDragStart = this.handleOnDragStart.bind(this);
        this.posicaoTargets.forEach((pos) => {
            pos.addEventListener("dragstart", this.boundHandlerOnDragStart);
        })

        this.boundHandlerOnDragOver = this.handleOnDragOver.bind(this);
        this.boundHandlerOnDrop = this.handleOnDrop.bind(this);
        this.grupoTargets.forEach((grupo) => {
            grupo.addEventListener("dragover", this.boundHandlerOnDragOver);
            grupo.addEventListener("drop", this.boundHandlerOnDrop);
        })

    }

    disconnect() {
        console.log("Group Disconnected");
    }

    handleOnDragStart(event) {
        event.dataTransfer.setData("text", event.target.id);
    }

    handleOnDragOver(event) {
        event.preventDefault();
    }

    handleOnDrop(event) {
        event.preventDefault();
        let posicao_id = event.dataTransfer.getData("text").replace("dd_posicao_", "");
        let params = {
            posicao_id,
            grupo_trabalho_id: event.target.closest("turbo-frame").id.replace("grupo_trabalho_", "")
        }

        post(`/clientes/mover`, {
            body: params,
            responseKind: 'turbo-stream'
        });


    }


}