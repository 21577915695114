import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
    static targets = ["mobileMenu", "closeButtonContainer", "backdrop", "offCanvas"]

    connect() {

    }

    close() {

        Promise.all([
            leave(this.backdropTarget),
            leave(this.closeButtonContainerTarget),
            leave(this.offCanvasTarget)

        ]).then(() => {
            this.mobileMenuTarget.classList.add('hidden')
        });
    }

    open() {
        this.mobileMenuTarget.classList.remove('hidden')
        enter(this.mobileMenu)
        enter(this.backdropTarget)
        enter(this.closeButtonContainerTarget)
        enter(this.offCanvasTarget)
    }

}