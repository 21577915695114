
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['evento', 'subtitulo', 'pontos', 'maximoPontos', 'criterios']
    connect() {
        let pontos = this.eventoTarget.dataset['pontos']
        if (pontos != undefined) {
            this.parseData(this.eventoTarget.dataset)
        }
    }

    createListItem(criterio) {
        const div = document.createElement('div')
        div.classList.add('flex', 'mb-2')

        div.innerHTML = '<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /></svg>'

        const titulo = document.createElement('p')
        titulo.setAttribute('class', 'text-sm text-gray-700')
        titulo.textContent = criterio.titulo;

        const subtitulo = document.createElement('p')
        subtitulo.setAttribute('class', 'text-sm text-gray-500')
        subtitulo.textContent = criterio.descricao;

        const content = document.createElement('div')
        content.appendChild(titulo)
        content.appendChild(subtitulo)

        div.appendChild(content)

        return div


    }
    updateInfo({ pontos, maximoPontos, criterios, subtitulo }) {
        this.subtituloTarget.innerHTML = ''
        this.subtituloTarget.classList.add('hidden')
        this.subtituloTarget.innerHTML = subtitulo
        this.subtituloTarget.classList.remove('hidden')
        if (criterios.length > 0) {
            this.criteriosTarget.classList.remove('hidden')
            let list = this.criteriosTarget.querySelector('#list')
            list.innerHTML = ''
            criterios.forEach((criterio) => {
                list.appendChild(this.createListItem(criterio))
            })
        }

        this.pontosTarget.innerHTML = pontos
        this.maximoPontosTarget.innerHTML = maximoPontos
    }

    parseData(dataset) {
        let pontos = '...'
        let maximoPontos = '...'
        let subtitulo = ''
        let criterios = []
        if (dataset['pontos'] != undefined) {
            pontos = `${dataset['pontos']} pontos`
            maximoPontos = `${dataset['maximoPontos']} pontos`
            subtitulo = dataset['subtitulo']
            criterios = JSON.parse(dataset['criterios'] || "[]")
        }

        this.updateInfo({ pontos, maximoPontos, criterios, subtitulo })
    }

    change(event) {
        this.parseData(event.target.selectedOptions[0].dataset)
    }
}