import { get } from "@rails/request.js";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        url: String,
        page: Number,
    };


    connect() {
        debugger;
        this.scroll = this.scroll.bind(this);
        this.pageValue = this.pageValue || 1;
        document.addEventListener('scroll', this.scroll);
        console.log("Connected");
    }

    scroll() {
        if (this.scrollReachedEnd) {
            this._fecthNewPage();
        }
    }

    async _fecthNewPage() {
        const url = new URL(this.url);
        url.searchParams.set('page', this.pageValue);

        await get(url.toString(), {
            responseKind: 'turbo-stream'
        });

        this.pageValue += 1;
    }

    get scrollReachedEnd() {
        debugger;
        const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
        const distanceToBottom = scrollHeight - (scrollTop + clientHeight);
        return distanceToBottom < 20;
    }

}