const adapter = Turbo.navigator.delegate.adapter;
const progressBar = adapter.progressBar;
const session = Turbo.session;
let progressBarTimeout = null;

document.addEventListener('turbo:before-fetch-request', function (e) {
  const target = e.target;
  
  if (!(target instanceof HTMLElement) || target.getAttribute('data-turbo-progress-bar') !== 'true') {
    return;
  }

  if (!progressBarTimeout) {
    progressBar.setValue(0);
  }

  progressBarTimeout = window.setTimeout(() => progressBar.show(), 0);
  document.body.classList.add('cursor-wait');
});

document.addEventListener('turbo:before-fetch-response', () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBar.hide();
    progressBarTimeout = null;
    document.body.classList.remove('cursor-wait');
  }
});