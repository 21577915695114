
import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";

var im = new Inputmask({
    alias: "currency",
    prefix: 'R$ ',
    radixPoint: ',',
    allowMinus: false,
    autoGroup: true,
    groupSeparator: '',
    groupSize: 3,
    digits: 0,
    digitsOptional: false,
    clearMaskOnLostFocus: false
});


export default class extends Controller {
    connect() {
        im.mask(this.element);
    }
}