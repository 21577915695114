
import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HC_solidGauge from 'highcharts/modules/solid-gauge';

HC_more(Highcharts);
HC_solidGauge(Highcharts)

export default class extends Controller {
    static targets = ["canvas", "mobile_menu"]

    static values = {
        auto: Number,
        nivel: Number,
        ciclo: Number,
        raro: Number,
    };


    connect() {

        this.chart = Highcharts.chart(this.canvasTarget.id, {

            chart: {
                type: 'solidgauge',
                height: '70%',
            },
            credits: {
                enabled: false
            },

            title: {
                text: 'Progresso',
                style: {
                    fontSize: '24px'
                }
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '16px'
                },
                pointFormatter: function () {
                    return `${this.series.name}<br><span style="font-size:2em; color: ${this.series.data[0].color}; font-weight: bold">${this.y / 20}</span>`
                },
                positioner: function (labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: (this.chart.plotHeight / 2) + 15
                    };
                }
            },

            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ // Track for Move
                    outerRadius: '112%',
                    innerRadius: '95%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, { // Track for Exercise
                    outerRadius: '92%',
                    innerRadius: '75%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, { // Track for Stand
                    outerRadius: '73%',
                    innerRadius: '56%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[3])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                },
                { // Track for Stand
                    outerRadius: '53%',
                    innerRadius: '36%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[4])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }
                ]
            },

            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },

            series: this.getSeries({ auto: this.autoValue, nivel: this.nivelValue, ciclo: this.cicloValue, raro: this.raroValue })
        });
    }

    getSeries({ auto, nivel, ciclo, raro }) {
        return [{
            name: 'Raro',
            data: [{
                color: Highcharts.getOptions().colors[0],
                radius: '112%',
                innerRadius: '95%',
                y: raro * 20
            }]
        }, {
            name: 'Esperado Pelo Nível',
            data: [{
                color: Highcharts.getOptions().colors[2],
                radius: '92%',
                innerRadius: '75%',
                y: nivel * 20
            }]
        }, {
            name: 'Meta',
            data: [{
                color: Highcharts.getOptions().colors[3],
                radius: '73%',
                innerRadius: '56%',
                y: ciclo * 20
            }],

        },
        {
            name: 'Auto Avaliação',
            data: [{
                color: Highcharts.getOptions().colors[4],
                radius: '53%',
                innerRadius: '36%',
                y: auto * 20
            }],

        }
        ]
    }

    update(event) {
        let { tab } = event.params;
        this.chart.update({ series: this.getSeries(event.params) });

        const tabEvent = new CustomEvent("tab", { detail: tab })
        this.element.dispatchEvent(tabEvent)

    }

    mobileChange(event) {
        let dataset = this.mobile_menuTarget.options[event.srcElement.selectedIndex].dataset;
        this.update({
            params: {
                eixo: dataset.eixoParam,
                auto: dataset.autoParam,
                raro: dataset.raroParam,
                ciclo: dataset.cicloParam,
                nivel: dataset.nivelParam,
                tab: dataset.tabParam,
            }
        });
    }




}
