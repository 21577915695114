import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

export default class extends Controller {
    static targets = ["canvas", "evolucoes", "evolucao",
        "maturidade_agir_descricao", "maturidade_agir_detalhes",
        "maturidade_cuidar_descricao", "maturidade_cuidar_detalhes",
        "maturidade_inovar_descricao", "maturidade_inovar_detalhes",
        "maturidade_tecnica_descricao", "maturidade_tecnica_detalhes",
        "maturidade_negocio_descricao", "maturidade_negocio_detalhes"]

    static values = {
        carreira: Object,
        eu: Object,
        objetivo: Object,
        raro: Object,
    };




    connect() {
        let series = [{
            type: 'column',
            name: this.carreiraValue.nome,
            color: this.carreiraValue.cor,
            data: this.carreiraValue.eixos
        }, {
            type: 'column',
            name: this.euValue.nome,
            color: 'rgba(20, 184, 166,0.8)',
            data: this.euValue.eixos
        },
        ];

        if (Object.keys(this.objetivoValue).length > 0) {
            series.push(
                {
                    type: 'column',
                    name: this.objetivoValue.nome,
                    color: 'rgba(240, 230, 140,0.8)',
                    data: this.objetivoValue.eixos
                }
            )
        }
        if (this.raroValue) {
            series.push(
                {
                    type: 'column',
                    name: this.raroValue.nome,
                    color: 'rgba(221, 160, 221,0.8)',
                    data: this.raroValue.eixos
                }
            )
        }

        this.chart = Highcharts.chart(this.canvasTarget.id, {
            chart: {
                polar: true,
                plotBorderColor: '#346691',
                plotBorderWidth: 0,
                height: '100%',

            },
            title: {
                text: ''
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
            },
            xAxis: {
                categories: ['Cuidar', 'Técnica', 'Inovar', 'Negócio', 'Agir'],
                gridLineColor: '#346691',
                gridLineWidth: 1,
                lineWidth: 1
            },
            yAxis: {
                gridLineInterpolation: 'polygon',
                gridLineDashStyle: 'longdash',
                gridLineColor: '#346691',
                gridLineWidth: 1,
                lineWidth: 0,
                tickInterval: 2
                // min: 0
            },
            credits: false,
            series: series,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0
                }
            },
        });
    }
    updateCarreiraFromSelect(event) {
        let { eixos, nome, descricoes, cor } = JSON.parse(event.target.selectedOptions[0].id);
        this.update(eixos, nome, descricoes, cor);
    }

    updateCarreira(event) {
        let { eixos, nome, descricoes } = event.params.payload;
        let cor = event.params.cor.toUpperCase();
        this.update(eixos, nome, descricoes, cor);

        let selected = `border-indigo-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`;
        let unselected = "cursor-pointer text-sm border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

        this.evolucaoTargets.forEach(target => {
            target.setAttribute('class', unselected);
        })
        event.srcElement.setAttribute('class', selected);

    }
    update(eixos, nome, descricoes, cor) {
        this.chart.series[0].setData(eixos);
        this.chart.series[0].setName(nome);
        this.chart.series[0].update({ color: cor });

        this.objetivoValue.nome
        this.chart.redraw();
        this.updateDescricoes(descricoes);
    }

    updateDescricoes(descricoes) {

        this.maturidade_cuidar_descricaoTarget.textContent = descricoes.cuidar.descricao;
        this.maturidade_cuidar_detalhesTarget.textContent = descricoes.cuidar.detalhes;

        this.maturidade_inovar_descricaoTarget.textContent = descricoes.inovar.descricao;
        this.maturidade_inovar_detalhesTarget.textContent = descricoes.inovar.detalhes;

        this.maturidade_agir_descricaoTarget.textContent = descricoes.agir.descricao;
        this.maturidade_agir_detalhesTarget.textContent = descricoes.agir.detalhes;

        this.maturidade_tecnica_descricaoTarget.textContent = descricoes.tecnica.descricao;
        this.maturidade_tecnica_detalhesTarget.textContent = descricoes.tecnica.detalhes;

        this.maturidade_negocio_descricaoTarget.textContent = descricoes.negocio.descricao;
        this.maturidade_negocio_detalhesTarget.textContent = descricoes.negocio.detalhes;

    }

}
